* {
  box-sizing: border-box;
}

a {
  transition: opacity 0.1;
}

a:hover {
  opacity: 0.8;
}

[data-braintree-id="card"] {
  border: 0;
}

[data-braintree-id="sheet-container"] {
  margin-bottom: 0;
}

[data-braintree-id="cvv-field-group"] {
  margin-bottom: 0 !important;
}

.braintree-placeholder, [data-braintree-id="save-card-field-group"], [data-braintree-id="card-sheet-header"] {
  display: none;
}

.braintree-sheet__content--form {
  padding-bottom: 0;
}

@media (max-width: 1200px) {
  .BrainhubCarousel .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    align-items: flex-start;
  }
}

@media (min-width: 1200px) {
  .BrainhubCarouselItem {
    min-height: 600px;
  }
}
